const palette = {
    patPink: '#e6057f',
    patPinkShade: '#b9006f',
    patPinkLight: '#e31e87',
    patGrey: '#e6e6e6',
    patYellowShade: '#fdd52c',
    patBlueShade: '#00618d',
    patBlueLight: '#0093d6',
    orange: '#e93e30',
    orangeHighlight: '#f05b49',
    blackGradient1: '#111',
    blackGradient2: '#222',
    blackGradient3: '#333',
    blackGradient4: '#525252',
    blackGradient6: '#666',
    blackGradient9: '#999',
    blackGradientb: '#b2b2b2',
    gray: '#cccecf',
    grayLight: '#e7e7e7',
    offWhite: '#f4f4f4',
    patronerBlueGradient: '#00618d',
    patronerLightBlue: '#d2eef9',
    patronerLightPeer: '#90c9e0',
    tonerlagretLightGreen: '#6f8f56',
    inkySkyBlueShadeLight: '#deedf9',
};
const theme = {
    siteId: 'lamportillallt',
    palette,
    radius: '3px',
    col: {
        primary: {
            alt: 'white',
            fg: '#8fb132',
            peer: '#448227',
        },
        secondary: {
            alt: '#707070',
            fg: '#8fb132',
            peer: '#a1d788',
        },
        tertiary: {
            alt: 'white',
            fg: '#707070',
            peer: '#c8c8c8',
        },
        panel: {
            peer: 'white',
            fg: '#ebebeb',
            alt: 'black',
        },
        info: {
            alt: 'white',
            fg: '#8fb132',
            peer: '#448227',
        },
        error: {
            alt: '#f15c4a',
            fg: '#ffe5e6',
            peer: 'white',
        },
        pale: {
            alt: 'black',
            fg: '#f8f8fa',
            peer: 'white',
        },
        splash: {
            alt: '#343434',
            fg: 'white',
            peer: 'white',
        },
        vars: {
            priceDiscountActive: '#e81818',
            bodyBg: '#f8f8fa',
            textLinkColor: '#215e98',
        },
    },
    skipColorClasses: true,
    font: {
        family: 'Poppins, Helvetica, sans-serif',
        header: {
            family: 'Poppins, Arial, Helvetica, sans-serif',
        },
        special: {
            family: 'Poppins, Arial, Helvetica, sans-serif',
        },
        size: {
            x4: '32px',
            x3: '24px',
            x2: '20px',
            x1: '18px',
            m: '15.6px',
            s1: '14px',
            s2: '12px',
            s3: '10px',
        },
    },
    conf: {
        showPDPLink: true,
        showBreadCrumbs: true,
        limitHeaderCols: true,
    },
};
export default theme;
