import { DefaultTheme } from '@spa-core/theme/interfaces'

export const NAME: string = 'ColumnCard'

export type ColumnCardTheme = {
    layout?: ColumnCartLayout
} & DefaultTheme

export enum ColumnCartLayout {
    SINGLE_COLUMN = 'singleColumn',
    DOUBLE_COLUMN = 'doubleColumn',
}
